<template>
  <div class="flats-table">
    <table>
      <thead>
        <tr :class="order">
          <th scope="col" class="flats-table__image">Планировка</th>
          <th
            scope="col"
            class="flats-table__rooms -sortable"
            :class="{ active: orderby == 'layout.room_count' }"
            @click="orderBy('layout.room_count')"
          >
            Комнаты<Icon icon="darr" />
          </th>
          <th
            scope="col"
            class="flats-table__area -sortable"
            :class="{ active: orderby == 'layout.area' }"
            @click="orderBy('layout.area')"
          >
            Площадь<Icon icon="darr" />
          </th>
          <th
            scope="col"
            class="flats-table__floor -sortable"
            :class="{ active: orderby == 'floor.number' }"
            @click="orderBy('floor.number')"
          >
            Этаж<Icon icon="darr" />
          </th>
          <!-- <th scope="col" class="flats-table__building -sortable" :class="{ active: orderby == 'building' }" @click="orderBy('building')">
						Корпус<Icon icon="darr" />
					</th> -->
          <th v-if="!isCommerce" scope="col" class="flats-table__mortgage">Ипотека</th>
          <th
            scope="col"
            class="flats-table__cost -sortable"
            :class="{ active: orderby == 'cost' }"
            @click="orderBy('cost')"
          >
            <template v-if="!hidePrice">Стоимость<Icon icon="darr" /></template>
          </th>
          <th scope="col" class="flats-table__favourite" />
        </tr>
      </thead>
      <tbody v-if="orderedFlats.length" class="flats-table-found">
        <template v-for="(flat, i) in orderedFlats" :key="i">
          <tr v-if="flat.isBanner" class="flats-table-avd__tr flats-table-avd__abnner">
            <td colspan="7">
              <component
                :is="flat.component"
                :banner="flat.data"
                @click="(e, banner) => $emit('bannerClick', e, banner)"
              />
            </td>
          </tr>
          <tr
            v-else
            @click="(e) => 
              routerPush(flat, e)
            "
          >
            <td class="flats-table__image">
              <img
                v-if="flat.layout.plan_image"
                :class="{ 'plan-stub': flat.layout.plan_image.stub }"
                width="96"
                height="96"
                loading="lazy"
                :src="flat.layout.plan_image.image_thumb"
                alt="Планировка"
              />
            </td>
            <td class="flats-table__rooms">
              <div class="flats-table__content">
                <router-link
                  :to="routeBuilder(flat)"
                  @click.stop
                >
                  {{ isCommerce ? flat.title : $filters.flatRoomNormalize(flat.layout) }}
                </router-link>
                <span v-if="!isCommerce">
                  №{{
                    flat.number
                  }}, корпус {{ flat.building }}
                </span>
              </div>
              <div class="flats-table__tags">
                <div
                  v-if="flat.status === 'booked'"
                  class="flats-table__tag btn btn-tag btn-orange"
                >
                  <Icon icon="lock2" />{{ $filters.bookedStatusNormalize(flat.layout.type) }}
                </div>
                <div
                  v-if="flatsVideo[flat.number]"
                  @click="clickViewHandler(flat)"
                  class="flats-table__tag btn btn-tag btn-lime flats-table__view-button"
                >
                  <Icon icon="image" />Вид из окна
                </div>
                <div
                  v-if="flat.properties._with_finished_layout"
                  class="flats-table__tag flats-table__tag--stroke btn btn-tag btn-lime"
                >
                  <Icon icon="roller" />c отделкой
                </div>
                <div
                  v-if="flat.properties._with_layout_furniture"
                  class="flats-table__tag btn btn-tag btn-lime"
                >
                  <Icon icon="roller" />отделка + мебель
                </div>
                <div
                  v-if="flat.properties._with_layout"
                  class="flats-table__tag btn btn-tag btn-lime"
                >
                  <Icon icon="roller" />доступна отделка
                </div>
                <div
                  v-if="flat.properties.with_design_project"
                  class="flats-table__tag btn btn-tag btn-lime"
                >
                  <Icon icon="gift" />дизайн-проект в подарок
                </div>
                <div
                  v-if="flat.properties.is_assignment"
                  class="flat__tag btn btn-tag btn-dark-grey "
                >
                  <Icon icon="key" />переуступка
                </div>
                <div
                  v-if="flat.discount"
                  class="flats-table__tag btn btn-tag btn-lime"
                >
                  <Icon icon="percent" />акция
                </div>
              </div>
            </td>
            <td
              class="flats-table__area"
              v-html="$filters.squareFormat(flat.layout.area)"
            />
            <td class="flats-table__floor">
              {{ flat.floor.number }}
            </td>
            <!-- <td class="flats-table__building">
						{{ flat.building }}
					</td> -->
            <td v-if="!isCommerce" class="flats-table__mortgage">
              {{
                $filters.priceFormat(
                  $filters.mortgagePay({
                    cost: flat.discount_cost,
                    applyCoef: mortgage.apply_coefficient,
                    coef: mortgage.coefficient,
                    percent: mortgage.min_mortgage_percent,
                    years: mortgage.max_mortgage_bank_time,
                  })
                )
              }}
            </td>
            <td v-if="!isCommerce" class="flats-table__cost">
              <div v-if="flat.discount" class="flats-table__new-price">{{ $filters.priceFormat(flat.discount_cost) }}</div>
              <div :class="{ 'flats-table__old-price': flat.discount }">{{ $filters.priceFormat(flat.cost) }}</div>
            </td>

            <td v-else class="flats-table__cost">
              <div v-if="flat.rent">{{ $filters.priceFormat(flat.rent) }} / мес</div>
              <div v-else class="flats-table__cost-green">Узнать подробнее</div>
            </td>
            <td class="flats-table__favourite">
              <FavouritesIcon :id="flat.id" />
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-if="false" class="flats-table-notfound">
        <tr>
          <td colspan="7">
            <div class="flats-table-notfound__title">Ничего не найдено</div>
            <p>Попробуйте изменить фильтр</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _orderBy from "lodash/orderBy";
import FavouritesIcon from "@/components/UI/FavouritesIcon.vue";
import flatsVideo from '@/content/flat.js'
export default {
  components: {
    FavouritesIcon,
  },
  inject: ["mainStore"],
  props: {
    flats: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
    },
    showAll: {
      type: Boolean,
      default: false
    },
    isCommerce: {
      type: Boolean,
      default: false
    },
    banners: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    hidePrice: {
      type: Boolean,
      default: false
    }
  },
  emits: ["bannerClick"],
  data() {
    return {
      orderby: "cost",
      order: "asc",
    };
  },
  computed: {
    orderedFlats() {
      let flats = this.flats;

      if (!this.showAll) {
        flats = flats.filter(
          (item) => this.$buildings[item.building].isAvailable
        );
      }

      if (this.limit) {
        flats = flats.slice(0, this.limit);
      }

      if (this.orderby) {
        flats = _orderBy(flats, this.orderby, this.order);
      }
      let bannerId = 0;
      const out = [];
      flats.forEach((item, i) => {
        if (i % 5 === 0) {
          if (this.banners.length > bannerId) {
            out.push(this.banners[bannerId]);
            bannerId++;
          }
        }
        out.push(item);
      });
      while (this.banners.length > bannerId) {
        out.push(this.banners[bannerId]);
        bannerId++;
      }

      return out;
    },
    flatsVideo () {
      return flatsVideo
    },
    mortgage() {
      return this.mainStore.state.data?.mortgage ?? {};
    },
  },
  methods: {
    orderBy(orderBy) {
      if (this.orderby == orderBy) {
        this.order == "desc" ? (this.order = "asc") : (this.order = "desc");
      } else {
        this.order = "desc";
      }
      this.orderby = orderBy;
    },
    routeBuilder (flat) {
      if (this.isCommerce) {
        return{
          name: flat.properties.root,
          params: {
            id: flat.uuid,
          },
        }
      }
      return {
        name: 'SearchPlanFlat',
        params: {
          korp: flat.building,
          sec: flat.section,
          floor: flat.floor?.number,
          fnumb: flat.number_on_floor,
          numb: flat.number,
          quantity: flat.layout?.room_count,
          id: flat.uuid,
        },
      }
    },
    clickViewHandler (flat) {
      this.$vfm.show(
        {
          component: 'PopupLive',
          bind: {
            videos: flatsVideo[flat.number],
            title: 'Виды из окна'
          },
        }
      )
    },
    routerPush (flat, e) {
      if (e.target.classList.contains('flats-table__view-button')) {
        return
      }
      this.$router.push(this.routeBuilder(flat))
    }
  },
};
</script>

<style scoped>
.flats-table {
  font-weight: normal;
  width: 100%;
}

.flats-table thead {
  user-select: none;
}

.flats-table__view-button {
  position: relative;
}
.flats-table__view-button::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.flats-table thead th.-sortable {
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.1s;
}

.flats-table thead th.-sortable svg {
  margin: -3px 0 0 5px;
  opacity: 0;
  transition: opacity 0.1s, transform 0.1s;
}

.flats-table thead th.-sortable.active svg {
  opacity: 1;
}

.flats-table thead tr.asc th.-sortable.active svg {
  transform: rotate(-180deg);
}

.flats-table thead th.active {
  color: #205640;
  font-weight: normal;
  text-decoration: underline;
}

body.-notouch .flats-table thead th.-sortable:hover {
  color: #205640;
}

.flats-table tbody.flats-table-found tr:not(.flats-table-avd__tr) td {
  cursor: pointer;
}

.flats-table tbody.flats-table-found tr {
  transition: box-shadow 0.1s;
}

body.-notouch
  .flats-table
  tbody.flats-table-found
  tr:not(.flats-table-avd__tr):hover {
  box-shadow: 0 0 60px rgb(0 0 0 / 0.1);
}

.flats-table__image {
  width: 136px;
}

.flats-table__image img {
  width: 96px;
  height: 96px;
}

.flats-table__rooms {
  position: relative;
  padding-right: 0;
}

.flats-table__rooms a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

.flats-table__rooms span {
  display: block;
  color: #646865;
  font-weight: normal;
  font-size: 14px;
}

.flats-table__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.flats-table__tag {
    white-space: nowrap;
}

.flats-table__tag--stroke {
  background-color: #fff!important;
  color: #205640!important;
  border-color: #205640!important;
  white-space: nowrap;
}
.flats-table__content {
  padding-right: 20px;
}
.flats-table__new-price {
  color: #509C3B;
  text-align: right;
}

.flats-table__old-price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-decoration-line: line-through;
  text-align: right;
  color: #C5C7C6;
}

.flats-table__label {
  line-height: 1;
}

.flats-table__label span {
  display: inline-block;
  padding: 7px 5px;
  border-radius: 2px;
  background: #509c3b;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.flats-table-notfound td {
  height: 300px;
  text-align: center;
}

.flats-table-notfound__title {
  margin-bottom: 7px;
  color: #8c8f8c;
  font-weight: normal;
  font-size: 24px;
}

.flats-table-notfound p {
  margin: 0;
  color: #b2b4b3;
  font-size: 16px;
}

.flats-table-avd__tr td {
  padding: 0 !important;
}

.flats-table-avd__abnner + .flats-table-avd__abnner td {
  border-bottom: unset;
}

.flats-table-avd__abnner + .flats-table-avd__abnner :deep(.abnner) {
  margin-top: 12px;
}

.flats-table__cost-green {
  color: #509C3B;
}
</style>
